import React from "react";
import * as moment from "moment-timezone";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { API, Auth } from "aws-amplify";
import { useTable, useSortBy } from "react-table";
import { Table } from "reactstrap";

function DeleteModal(props) {
  const modal = props.modal;
  const toggle = props.toggle;
  const confirm = props.confirm;
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confimration</ModalHeader>
      <ModalBody>Are you sure?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={confirm}>
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

async function postData(path, data) {
  const init = { body: data };
  //const result = await API.post('admin',path, init)
  //return result;
  const myInit = {
    headers: { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: data,
  };
  return await API.post("admin", path, myInit);
}

async function getData(path) {
  console.log("getting data");
  //return await API.get('admin',path);
  const myInit = {
    headers: { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
  };
  return await API.get("admin", path, myInit);
}

async function delData(path, data = null) {
  console.log("delete data");
  //return await API.del('admin',path);
  const myInit = {
    headers: { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
  };
  if (data != null) {
    myInit.body = data;
  }
  return await API.del("admin", path, myInit);
}

const localizeTZ = (dt) => {
  return dt
    ? moment(dt + ".000Z")
        .tz("Europe/Tallinn")
        .format("YYYY-MM-DD HH:mm")
    : dt;
};

const DynTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "started_at",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
  );

  return (
    <Table striped hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const ModelTypes = {
  hourly: "Hourly",
  daily: "Daily",
};

export { getData, delData, postData, DeleteModal, DynTable, localizeTZ, ModelTypes };

export default DeleteModal;
