import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import { getData, delData, DeleteModal } from "./../Utils";

class Types extends Component {
  constructor(props) {
    super(props);
    this.state = { types: [], modal: false, to_delete: 0 };
    this.addNewPressed = this.addNewPressed.bind(this);
    this.modiffyPressed = this.modiffyPressed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.confirm = this.confirm.bind(this);
  }
  componentDidMount() {
    getData("types").then((types) => {
      console.log(types);
      this.setState({ types });
    });
  }

  addNewPressed() {
    this.props.history.push("/type/");
  }

  modiffyPressed(id) {
    this.props.history.push("/type/" + id);
  }
  toggle(id) {
    const { modal } = this.state;
    this.setState({ to_delete: id });
    this.setState({ modal: !modal });
  }

  confirm() {
    console.log(this.state.to_delete);
    this.setState({ modal: false });
    delData("types/", { id: this.state.to_delete }).then((r) => {
      const types = this.state.types.filter((p) => p.id !== this.state.to_delete);
      this.setState({ types });
    });
  }
  render() {
    const rows = this.state.types.map((type) => (
      <tr key={type.id}>
        <th>{type.name}</th>
        <th>
          <Button color="primary" onClick={() => this.modiffyPressed(type.id)}>
            Modify
          </Button>{" "}
          <Button color="danger" onClick={() => this.toggle(type.id)} style={{ margin: "2px" }}>
            Delete
          </Button>
        </th>
      </tr>
    ));
    return (
      <React.Fragment>
        <Button color="primary" onClick={this.addNewPressed}>
          Add New
        </Button>
        <Table striped hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <DeleteModal modal={this.state.modal} toggle={this.toggle} confirm={this.confirm} />
      </React.Fragment>
    );
  }
}

export default Types;
