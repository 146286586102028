import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getData, postData } from "./../Utils";

function Type() {
  const params = useParams();
  const empty_type = { name: "", price_start: 0, price_hour: 0, price_24hour: 0, relocation_price: 0, master_type_id: 1, description: "", image: null };
  const [type, setType] = useState(empty_type);
  const [masterTypes, setMasterTypes] = useState([]);

  useEffect(() => {
    getData("types/master").then((masterTypes) => {
      console.log(masterTypes);
      setMasterTypes(masterTypes);
    });
  }, []);
  useEffect(() => {
    if (params.id) {
      getData("type/" + params.id).then((type) => {
        console.log(type);
        setType(type);
      });
    }
  }, []);
  function save() {
    postData("types", type).then((r) => {
      console.log(r);
      window.location.href = "/types";
    });
  }
  function onChangeValue(event) {
    setType({ ...type, [event.target.name]: event.target.value });
  }
  function handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setType({ ...type, image: reader.result });
    };
    reader.readAsDataURL(file);
  }
  const masterTypesSelect = masterTypes.map((type) => (
    <option key={type.id} value={type.id}>
      {type.name}
    </option>
  ));
  return (
    <Form>
      <FormGroup>
        <Label>Type</Label>
        <Input type="select" name="master_type_id" value={type.master_type_id} onChange={onChangeValue}>
          {masterTypesSelect}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Name</Label>
        <Input type="text" name="name" value={type.name || ""} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Starting price</Label>
        <Input type="text" name="price_start" value={type.price_start || ""} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Price per hour</Label>
        <Input type="text" name="price_hour" value={type.price_hour || ""} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Price for first 24 hours</Label>
        <Input type="text" name="price_24hour" value={type.price_24hour || ""} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Relocation Price</Label>
        <Input type="text" name="relocation_price" value={type.relocation_price || 0} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Description</Label>
        <Input type="textarea" name="description" value={type.description || ""} onChange={onChangeValue} />
      </FormGroup>
      <FormGroup>
        <Label>Length</Label>
        <Input type="text" name="length" value={type.length || ""} onChange={onChangeValue} /> m
      </FormGroup>
      <FormGroup>
        <Label>Width</Label>
        <Input type="text" name="width" value={type.width || ""} onChange={onChangeValue} /> m
      </FormGroup>
      <FormGroup>
        <Label>Height</Label>
        <Input type="text" name="height" value={type.height || ""} onChange={onChangeValue} /> m
      </FormGroup>
      <FormGroup>
        <Label>Max Load</Label>
        <Input type="text" name="max_load" value={type.max_load || ""} onChange={onChangeValue} /> kg
      </FormGroup>
      Image:
      <div className="logo-image-preview">{type.image && <img alt="preview" className="logo-image-preview" src={type.image} />}</div>
      <input type="file" onChange={(e) => handleImageChange(e)} />
      <Button color="primary" onClick={() => save()}>
        Save
      </Button>
    </Form>
  );
}

class Type_C extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterTypes: [],
    };
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.id = params.id;
    }
    this.onChangeValue = this.onChangeValue.bind(this);
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    getData("types/master").then((masterTypes) => {
      console.log(masterTypes);
      this.setState({ masterTypes });
    });

    if (this.id) {
      getData("type/" + this.id).then((type) => {
        console.log(type);
        this.setState(type);
      });
    } else {
      this.setState({ name: "", price_start: 0, price_hour: 0, price_24hour: 0, relocation_price: 0, master_type_id: 1, description: "" });
    }
  }
  onChangeValue(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  save() {
    postData("types", this.state).then((r) => {
      console.log(r);
      window.location.href = "/types";
    });
  }
  render() {
    const masterTypes = this.state.masterTypes.map((type) => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ));
    return (
      <Form>
        <FormGroup>
          <Label>Type</Label>
          <Input type="select" name="master_type_id" value={this.state.master_type_id} onChange={this.onChangeValue}>
            {masterTypes}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Name</Label>
          <Input type="text" name="name" value={this.state.name || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Starting price</Label>
          <Input type="text" name="price_start" value={this.state.price_start || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Price per hour</Label>
          <Input type="text" name="price_hour" value={this.state.price_hour || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Price for first 24 hours</Label>
          <Input type="text" name="price_24hour" value={this.state.price_24hour || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Relocation Price</Label>
          <Input type="text" name="relocation_price" value={this.state.relocation_price || 0} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input type="textarea" name="description" value={this.state.description || ""} onChange={this.onChangeValue} />
        </FormGroup>
        <FormGroup>
          <Label>Length</Label>
          <Input type="text" name="length" value={this.state.length || ""} onChange={this.onChangeValue} /> m
        </FormGroup>
        <FormGroup>
          <Label>Width</Label>
          <Input type="text" name="width" value={this.state.width || ""} onChange={this.onChangeValue} /> m
        </FormGroup>
        <FormGroup>
          <Label>Height</Label>
          <Input type="text" name="height" value={this.state.height || ""} onChange={this.onChangeValue} /> m
        </FormGroup>
        <FormGroup>
          <Label>Max Load</Label>
          <Input type="text" name="max_load" value={this.state.max_load || ""} onChange={this.onChangeValue} /> kg
        </FormGroup>
        <Button color="primary" onClick={() => this.save()}>
          Save
        </Button>
      </Form>
    );
  }
}

export default Type;
