import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import DeleteModal from "../Utils";
import { getData, delData, DynTable } from "../Utils";
import { useHistory } from "react-router-dom";

export default function PaymentModels() {
  const history = useHistory();
  const [models, setPaymentModels] = useState([]);
  const [modal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState(0);

  useEffect(() => {
    getData("payment_models").then((models) => setPaymentModels(models));
  }, []);

  const toggle = (id) => {
    setToDelete(id);
    setModal(!modal);
  };

  const confirm = () => {
    setModal(false);
  };
  return (
    <>
      <Button color="primary" onClick={() => history.push("/paymentModel")}>
        Add New
      </Button>
      <DrawDynamicPaymentModelsTable data={models} toggle={toggle} />
      <DeleteModal modal={modal} toggle={toggle} confirm={confirm} />
    </>
  );
}

function DrawDynamicPaymentModelsTable(props) {
  const columns = [
    {
      Header: "Models",
      columns: [
        {
          Header: "Model id",
          accessor: "id",
          sortType: "basic",
        },
        {
          Header: "Name",
          accessor: "name",
          sortType: "basic",
        },
        {
          Header: "Type",
          accessor: "model_type",
          sortType: "basic",
        },
        {
          Header: "Actions",
          accessor: "actions",
        },
      ],
    },
  ];
  const { toggle, data } = props;
  data.map((d) => {
    d.actions = (
      <>
        <a href={`/paymentModel/${d.id}`}>
          <Button color="primary" style={{ margin: "2px" }}>
            Details
          </Button>
        </a>
      </>
    );
    return d;
  });
  return <DynTable columns={columns} data={data} />;
}
